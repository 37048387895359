<template>
  <v-card class="elevation-1">
    <v-card-title>
      <span class="mt-4" v-if="formState!==null">{{formState}} Record</span>
      <v-btn
        icon
        small
        class="mt-4"
        text
        @click="formState='New'"
        v-else>
        <v-icon>{{addIcon.icon}}</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-form>
        <v-text-field
          v-model="code"
          label="Code"
          outlined
          dense
          placeholder="Code"
          :class="formErrorFields.codeErrorMessage.length > 0 ? 'mb-3' : ''"
          :error-messages="formErrorFields.codeErrorMessage"
          :disabled="formState===null"
          @keydown="formError().remove('code', formErrorFields)"
        ></v-text-field>

        <v-text-field
          v-model="title"
          label="Title"
          outlined
          dense
          placeholder="Title"
          :class="formErrorFields.titleErrorMessage.length > 0 ? 'mb-3' : ''"
          :error-messages="formErrorFields.titleErrorMessage"
          :disabled="formState===null"
          @keydown="formError().remove('title', formErrorFields)"
        ></v-text-field>

        <v-autocomplete
          v-model="ytdPost"
          :items="ytdPosts"
          outlined
          dense
          clearable
          label="YTD Post"
          class="mb-3"
          :error-messages="formErrorFields.ytdPostErrorMessage"
          :disabled="formState===null"
          @keydown="formError().remove('ytdPost', formErrorFields)"
        ></v-autocomplete>

        <v-btn
          color="primary"
          :disabled="formState===null"
          :loading="loading"
          @click="submit">
          Submit
        </v-btn>
        <v-btn
          outlined
          class="mx-2"
          :disabled="formState===null"
          @click="reset"
        >
          Cancel
        </v-btn>
      </v-form>
    </v-card-text>

    <snackbar
      v-if="responseMessage.length > 0"
      :show="responseMessage.length > 0"
      :text="responseMessage"
      :color="responseMessageStatus"
      @close="responseMessage=''"
    >
    </snackbar>
  </v-card>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { mdiPlusOutline } from '@mdi/js'
import crud from '@/composables/crud'
import snackbar from '@/components/SnackBar.vue'
import formError from '@/composables/formErrorHandler/formError'

export default {
  components: {
    snackbar,
  },

  props: ['data'],

  setup(props, { emit }) {
    const loading = ref(false)
    const message = ref('')
    const addIcon = { icon: mdiPlusOutline, name: 'mdiPlusOutline' }
    const code = ref('')
    const title = ref('')
    const ytdPost = ref('')
    const ytdPosts = [
      { value: 'allow', text: 'Allowance' },
      { value: 'bonus', text: 'Bonus' },
      { value: 'holiday', text: 'Holiday' },
      { value: 'mealsub', text: 'Meal Subsidy' },
      { value: 'ndiff', text: 'Night Diff' },
      { value: 'nontax', text: 'Non Taxable' },
      { value: 'others', text: 'Others' },
      { value: 'otpay', text: 'OT Pay' },
      { value: 'regpay', text: 'Regular Pay' },
      { value: '13thmonth', text: '13TH Month' },
    ]
    const formState = ref(null)
    const formErrorFields = ref({
      codeErrorMessage: '',
      titleErrorMessage: '',
      ytdPostErrorMessage: '',
    })

    watch(() => props.data, () => {
      code.value = props.data.code
      title.value = props.data.title
      ytdPost.value = props.data.ytd_post
      formState.value = 'Edit'
    })

    const {
      store,
      update,
      responseMessage,
      responseMessageStatus,
    } = crud()

    const submit = async () => {
      const formData = {
        code: code.value,
        title: title.value,
        ytdPost: ytdPost.value,
      }

      if (formState.value === 'New') {
        const uri = `${process.env.VUE_APP_URI}/api/payroll/adjustments`
        await store(uri, loading, formData, formErrorFields)
      } else {
        const uri = `${process.env.VUE_APP_URI}/api/payroll/adjustments/${props.data.id}`
        await update(uri, loading, formData, formErrorFields)
      }

      message.value = responseMessage.value
      emit('updateUniqueID')
    }

    const reset = () => {
      formState.value = null
      code.value = ''
      title.value = ''
      ytdPost.value = ''
    }

    return {
      code,
      title,
      ytdPost,
      ytdPosts,
      addIcon,
      formState,
      reset,
      submit,
      loading,
      message,
      formErrorFields,
      formError,
      responseMessage,
      responseMessageStatus,
    }
  },
}
</script>
